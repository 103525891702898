<template>
    <div>
        <LiefengContent>
            <template v-slot:title>{{ "签名模板管理" }}</template>
            <template v-slot:toolsbarRight>
                <Form :model="searchData" :label-colon="true" :inline="true" class="search">
                    <FormItem label="标题" :label-width="50" style="margin-bottom: 0">
                        <Input v-model.trim="searchData.title" placeholder="请输入标题" style="width: 160px"></Input>
                    </FormItem>
                    <Button type="primary" icon="ios-search" @click="getList" style="margin-right: 10px">查询</Button>
                    <Button type="success" @click="resetBtn" icon="ios-refresh" style="margin-right: 10px">重置</Button>
                    <Button type="primary" @click="addBtn" style="margin-right: 10px">创建文件</Button>
                </Form>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                    :talbeColumns="talbeColumns"
                    :tableData="tableData"
                    :loading="loading"
                    :fixTable="true"
                    :curPage="page"
                    :total="total"
                    :pagesizeOpts="[20, 30, 50, 100]"
                    :page-size="pageSize"
                    @hadlePageSize="hadlePageSize"
                    @tableSelect="tableSelect"
                ></LiefengTable>
            </template>
        </LiefengContent>
        <!-- 新增修改界面 -->
        <LiefengModal :title="addTitle" :value="addStatus" @input="openStatusFn" :fullscreen="true">
            <template v-slot:contentarea>
                <AddFrom :formData="formData" ref="addForm"></AddFrom>
            </template>
            <template v-slot:toolsbar>
                <Button type="info" @click="cancelAdd">取消</Button>
                <Button style="margin-left: 10px" type="primary" @click="saveAdd">确定</Button>
            </template>
        </LiefengModal>
        <!-- 分享并预览 -->
        <LiefengModal title="分享并预览" :value="officialStatus" @input="officialStatusFn" width="1150px" height="calc(100vh - 200px)">
            <template v-slot:contentarea>
                <QrCode v-if="officialStatus" :codeString="codeString" :infoId="qrCodeId" ref="QrCode" :qrOCdeName="qrOCdeName"></QrCode>
            </template>
        </LiefengModal>
    </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import QrCode from "./components/qrCode.vue"
import AddFrom from "./components/addForm.vue"
export default {
    components: { LiefengContent, LiefengTable, LiefengModal, QrCode, AddFrom },
    data() {
        return {
            searchData: { title: "" },
            // table表格数据
            tableData: [],
            loading: false,
            page: 1,
            pageSize: 20,
            total: 0,
            currentPage: 0,
            talbeColumns: [
                {
                    title: "模板名称",
                    align: "center",
                    key: "title",
                },
                {
                    title: "创建时间",
                    align: "center",
                    key: "gmtCreate",
                    render: (h, params) => {
                        return h("div", {}, params.row.gmtCreate && params.row.gmtCreate != "" ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")
                    },
                },
                {
                    title: "创建人",
                    align: "center",
                    key: "creator",
                },
                {
                    title: "签署份数",
                    align: "center",
                    key: "fillNum",
                },
                {
                    title: "文件ID",
                    align: "center",
                    key: "id",
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: "right",
                    width: 250,
                    render: (h, params) => {
                        return h(
                            "div",
                            {
                                style: {
                                    textAlign: "center",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.$get("/gateway/syinfopublish/api/pc/applyAccord/selectAccord", {
                                                    accordId: params.row.id,
                                                }).then(res => {
                                                    if (res && res.code == 200 && res.data) {
                                                        this.addTitle = "修改文件"
                                                        let data = JSON.parse(res.data.dataJson)
                                                        this.formData = {
                                                            title: res.data.title,
                                                            name: data.name,
                                                            type: "edit",
                                                            id: params.row.id,
                                                            remark:res.data.remark,
                                                            cachet:res.data.cachet
                                                        }
                                                        this.$refs.addForm.msg = data.content
                                                        if(data.content.indexOf('insert-view') > -1){
                                                            this.$refs.addForm.showInserBtn = false
                                                        }else{
                                                            this.$refs.addForm.showInserBtn = true
                                                        }
                                                        this.$refs.addForm.showImageUpload = false
                                                        if(res.data.cachet && res.data.cachet != ''){
                                                            this.$refs.addForm.showImg = false
                                                        }else{
                                                            this.$refs.addForm.showImg = true
                                                        }
                                                        this.$nextTick(() => {
                                                            this.$refs.addForm.showImageUpload = true
                                                            if(res.data.cachet && res.data.cachet){
                                                                this.$refs.addForm.imgData = [{type:'image',url:res.data.cachet}]
                                                            }else{
                                                                this.$refs.addForm.imgData = []
                                                            }
                                                        })
                                                        this.openStatusFn(true)
                                                    }
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "修改" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.$core.openLayerFrame({
                                                    type: 2,
                                                    title: "签署记录",
                                                    content: `/page#/autographdetial?id=${params.row.id}&title=${params.row.title}&userName=${params.row.userName}`,
                                                    area: ["100%", "100%"],
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "签署记录"
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                this.officialStatus = true
                                                this.qrCodeId = params.row.id
                                                this.qrOCdeName = params.row.title
                                                this.codeString = params.row.id
                                                this.$nextTick(() => {
                                                    this.$refs.QrCode.getOfficialScopeList(this.qrCodeId, "2", "44")
                                                })
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "二维码" //1001
                                ),
                                h(
                                    "span",
                                    {
                                        on: {
                                            click: () => {
                                                if (params.row.fillNum && params.row.fillNum > 0) {
                                                    this.$Modal.confirm({
                                                        title: "删除提示",
                                                        content: "<p>该数据已有填写记录，删除后将不可恢复，是否确定删除？</p>",
                                                        loading: true,
                                                        onOk: () => {
                                                            this.$post("/gateway/syinfopublish/api/pc/applyAccord/deleteAccord", {
                                                                accordId: params.row.id,
                                                            }).then(res => {
                                                                this.$Modal.remove()
                                                                if (res.code == 200) {
                                                                    this.$Message.success({
                                                                        background: true,
                                                                        content: "删除成功",
                                                                    })
                                                                    this.getList()
                                                                } else {
                                                                    this.$Message.error({
                                                                        background: true,
                                                                        content: res.desc,
                                                                    })
                                                                }
                                                            })
                                                        },
                                                    })
                                                } else {
                                                    this.$post("/gateway/syinfopublish/api/pc/applyAccord/deleteAccord", {
                                                        accordId: params.row.id,
                                                    }).then(res => {
                                                        if (res.code == 200) {
                                                            this.$Message.success({
                                                                background: true,
                                                                content: "删除成功",
                                                            })
                                                            this.getList()
                                                        } else {
                                                            this.$Message.error({
                                                                background: true,
                                                                content: res.desc,
                                                            })
                                                        }
                                                    })
                                                }
                                            },
                                        },
                                        style: {
                                            width: "65px",
                                            marginRight: "10px",
                                            textAlign: "center",
                                            color: "#2d8cf0",
                                            cursor: "pointer",
                                            userSelect: "none",
                                        },
                                    },
                                    "删除" //1001
                                ),
                            ]
                        )
                    },
                },
            ],

            // 分享并预览模态框状态
            officialStatus: false,

            // 存放点击分享并预览的时候点击的当前的infoId
            qrCodeId: "",
            qrOCdeName: "", //信息标题
            codeString: "", // 传给组件的code

            // 新增修改模态框控制
            addStatus: false,
            addTitle: "创建文件",
            // 新增修改参数
            formData: {
                title: "",
                dataJson: "",
            },
        }
    },
    created() {
        this.getList()
    },
    methods: {
        // 方法部分
        // 保存按钮
        async saveAdd() {
            if (!this.formData.type || this.formData.type == "") {
                if (await this.$refs.addForm.save()) {
                    this.openStatusFn(false)
                    this.getList()
                }
            } else {
                if (await this.$refs.addForm.editSave()) {
                    this.openStatusFn(false)
                    this.page = this.currentPage
                    this.getList()
                }
            }
        },
        cancelAdd() {
            this.openStatusFn(false)
        },
        // 重置按钮
        resetBtn() {
            this.searchData.title = ""
            this.page = 1
            this.getList()
        },
        addBtn() {
            this.addTitle = "创建文件"
            this.formData = {
                title: "",
                name: "",
            }
            this.$refs.addForm.clearInput()
            this.$refs.addForm.showImg = true
            this.$refs.addForm.showImageUpload = false
            this.$nextTick(() => {
                this.$refs.addForm.showImageUpload = true
                this.$refs.addForm.imgData = []
            })
            this.openStatusFn(true)
        },
        // 控制新增修改模态框
        openStatusFn(status) {
            this.addStatus = status
        },
        // 分页改变接口
        hadlePageSize(val) {
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 选择表格事件
        tableSelect(val) {
            this.selectList = val
        },
        // 分享并预览模态框关闭事件
        officialStatusFn(status) {
            this.officialStatus = status
        },
        // 接口部分
        getList() {
            this.loading = true
            this.$get("/gateway/syinfopublish/api/pc/applyAccord/selectAccordDetailPage", {
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                title: this.searchData.title,
                page: this.page,
                pageSize: this.pageSize,
            }).then(res => {
                this.loading = false
                if (res && res.code && res.code == 200 && res.dataList) {
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.currentPage = res.currentPage
                } else {
                    this.$Message.error({
                        content: "获取数据失败",
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>
<style lang="less">
.operation-title {
    &::after {
        content: ">";
        position: "absolute";
        transform: rotate(90deg);
        display: block;
        margin-left: 2px;
        font-size: 10px;
    }
}
</style>
<style lang="less" scoped>
.table-left {
    float: left;
    width: 160px;
    /deep/.ivu-menu {
        width: 160px !important;
    }
}
.none-modal {
    /deep/.ivu-modal-close {
        display: none;
    }
}
/deep/.validate {
    &::before {
        content: "*";
        display: inline-block;
        margin-right: 4px;
        line-height: 1;
        font-family: SimSun;
        font-size: 14px;
        color: #ed4014;
    }
}
/deep/.ivu-tooltip-inner {
    width: 200px;
    white-space: normal;
}
/deep/.ivu-menu-item {
    z-index: 3;
}
</style>